import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Messages from "../../../lang/Messages";
import { useNavigate, useLocation } from "react-router-dom";
import "./RejectedPayment.scss";
import { Alert } from "@mui/material";

export const RejectedPayment = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { state } = useLocation();

  console.log("STATE REJECTED", state)

  return (
    <div className="reject__container">
      <div className="reject__payment">
        <Alert severity="error">{formatMessage(Messages.reject_payment_alert)}</Alert>
        <p>{formatMessage(Messages.reject_payment_detail)}</p>
        <button onClick={() => navigate(`/${Messages.route_checkout.id}`, { state: { currentStep: 1, selectedProduct: state } })}>{formatMessage(Messages.reject_payment_button)}</button>
      </div>
    </div>
  );
};
