import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { editContactAddress, getCitiesProvince, webhookCall } from "../../services/endPoints";
import { CityResponse, Product, StepsProps } from '../Types';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import Messages from '../../lang/Messages';
import { Confirmation } from './Steps/Confirmation';
import { FormDataContext } from '../../App';
import { Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export const CheckoutStepper = ({ externalCategory, externalAmount, externalId, steps }: StepsProps) => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const { isMobile, ga4Event } = useContext(FormDataContext);
    const { state } = useLocation();

    const [activeStep, setActiveStep] = useState(0);
    const [errors, setErrors] = useState<Record<string, string>>({} as Record<string, string>);
    const [formData, setFormData] = useState<Record<string, string | boolean>>({} as Record<string, string | boolean>);
    const [isLoadingCity, setIsLoadingCity] = useState<boolean>(false);
    const [cities, setCities] = useState<string[]>([]);
    const [product, setProduct] = useState<Product>(state ? state.selectedProduct ? state.selectedProduct : state : {} as Product);

    useEffect(() => {
      if(!state) sessionStorage.removeItem("f_data");

      if(externalAmount) {
        const externalProduct = {
          id: 999999,
          name: 'Producto Whatsapp',
          description: 'Producto vendido vía Whatsapp',
          endDate: '2999-01-01',
          imageUrl: '',
          pack: [],
          plans: [],
          currentPlanId: 999999,
          categoryCode: externalCategory,
          amount: externalAmount,
          isActive: true,
        }
        setProduct(externalProduct)
        console.log("FORMDATA", formData)
        setFormData({ ...formData, externalId })
      }
      if(state) {
        const { currentStep } = state
        if(currentStep) {
          setActiveStep(parseInt(currentStep));
          const formSession = sessionStorage.getItem('f_data')
          console.log("SELECTEDPRODUCT", formSession)
          if(formSession)
            setFormData(JSON.parse(formSession))
        }
      }
    }, [])

    useEffect(() => {
      const { nextStep } = formData
      if(nextStep && steps[activeStep].isAutomatic)
        handleNext(product.categoryCode);  
    }, [formData])

    const handleFormData = (key: string | Record<string, string | boolean>, value: string | undefined) => {
      console.log("KEY FORMDATA", key)
      if(typeof key === 'object') {
        setFormData({ ...formData, ...key });
        sessionStorage.setItem('f_data', JSON.stringify({ ...formData, ...key }))
        return;
      }
      setFormData({ ...formData, [key]: value || "" });
      sessionStorage.setItem('f_data', JSON.stringify({ ...formData, [key]: value || "" }))
  
      if(key === 'province') {
          setIsLoadingCity(true);
          const isCABA = value === 'Ciudad Autónoma de Buenos Aires';
          getCitiesProvince(isCABA ? 'localidades' : 'departamentos', { provincia: value, max: 200 }).then(({ data })  => {
            setIsLoadingCity(false);
            const { localidades, departamentos }: CityResponse = data
            const newCities = isCABA ? localidades?.map(({ nombre }) => _.capitalize(nombre))  || []: departamentos?.map(({ nombre }) => nombre) || []
            setCities(newCities)
            console.log(newCities)
          })        
      }
    };
  
    const handleError = (keyError: string, newValue: any, message: string) => {
      let newError = "";
      if (!newValue || (!!newValue && typeof newValue === "string" && !newValue.trim()))
        newError = message;
  
      setErrors({ ...errors, [keyError]: newError });
    };

    const isValidForm = () => {
      const requiredEmptyFields = steps[activeStep].components.filter(({ id, required }) => required && !formData[id as keyof Record<string,string>])
      requiredEmptyFields.forEach(({ id, error }) => setErrors(prevErrors => ({ ...prevErrors, [`${id}Error`]: error || '' })))
      return !requiredEmptyFields.length
    }
  
    const handleNext = (categoryCode: string) => {
      if (isValidForm()) {
        const eventName = steps[activeStep] ? `checkout_step_${activeStep + 1}_${formatMessage(Messages[`product_${categoryCode.toLowerCase()}` as keyof typeof Messages])}` : 'checkout_finalizado'
        const label = steps[activeStep] ? steps[activeStep].title : 'Fin proceso asociación'
        ga4Event({
          category: "Checkout",
          action: eventName,
          label
        })
        window.mt("send", "pageview", { page_title: 'Checkout', label, category: 'Checkout', action: eventName });

        const { externalId } = formData
        if(activeStep + 2 === steps.length) {
          const { firstName, lastName, phoneNumber, email, dni, subscriptionId } = formData
          const contactId = sessionStorage.getItem('c_id') || "";
          console.log("FORMDATA", { ...formData, contactId })
          editContactAddress(contactId, { identification_number: dni })
          if (externalId) webhookCall({ firstName, lastName, phoneNumber, email, dni, externalId, subscriptionId })
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        const action = steps[activeStep].action;
        if(action) action(formData)
      }        
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    return (
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', pt: 2, alignItems: 'center', gap: '30px' }}>
        <Stepper activeStep={activeStep}>
          {steps.map(({ title, mobileTitle }) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={title as string} {...stepProps}>
                <StepLabel {...labelProps}>{isMobile ? mobileTitle: title}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
          {activeStep === steps.length ? (
          //Stepper finished
          <React.Fragment>
            <Confirmation formData={formData} key={'confirmation-step'}/>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'center'}}>
              <button onClick={() => {navigate(`/${Messages.route_products.id}`)}}>Volver a selecciones</button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {React.createElement(steps[activeStep].component, { state: product, handleFormData, handleError, errors, formData, formFields: steps[activeStep].components, cities, handleNext })}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between', width: '100%', padding: '0', maxWidth: '360px' }}>
              <Button color="inherit" disabled={activeStep === 0 || activeStep === steps.length - 1} onClick={handleBack} sx={{ mr: 1 }}>{formatMessage(Messages.checkout_back_button)}</Button>
              {activeStep < steps.length && <Button disabled={steps[activeStep].type === 'payment'} onClick={() => handleNext(product.categoryCode)}>
                {activeStep < steps.length - 1 ? formatMessage(Messages.checkout_next_button) : formatMessage(Messages.checkout_finish_button)}
              </Button>}
            </Box>
            {activeStep < steps.length && steps[activeStep].type === 'payment' && 
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">{formatMessage(Messages.confirmation_payment_text)}</Alert>}
          </React.Fragment>
        )}
      </Box>
    );
  }