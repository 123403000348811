import { Club } from "./Club";
import { Benefits } from "./Benefits";
import { useIntl } from "react-intl";
import { useContext, useEffect, useState } from "react";
import "./Home.scss";
import Messages from "../../lang/Messages";
import { WinesMobile } from "./WinesMobile";
import { WinesDesktop } from "./WinesDesktop";
import { Reviews } from "./Reviews";
import { FormDataContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";

export const Home = () => {
  const { formatMessage } = useIntl();
  const { setIsCheckout, isMobile, ga4Event } = useContext(FormDataContext);
  const navigate = useNavigate();

  useEffect(() => {
    setIsCheckout(false);
    //etBackgroundImage();
  }, []);

  // const setBackgroundImage = () => {
  //   const image = new Image();
  //   image.src = "/home.jpg";

  //   const handleImageLoad = () => {
  //     setIsImageLoading(false);
  //     setImageData(image);
  //   };

  //   image.onload = handleImageLoad;

  //   return () => {
  //     image.onload = null;
  //   };
  // };

  // useEffect(() => {
  //   if (!isImageLoading) {
  //     const imageContainer = document.querySelector(
  //       ".home__img"
  //     ) as HTMLDivElement;
  //     console.log(imageContainer);
  //     if (!imageContainer) return;
  //     imageContainer.style.backgroundImage = `url(${imageData})`;
  //   }
  // }, [imageData]);

  const booking = () => {
    console.log("BOOKING")
    ga4Event(
      {
        category: "Association",
        action: "start_association",
        label: "Asociando desde Home",
      },
    );
    // sendGa4CustomEvent({
    //   data: {
    //     client_id: "979312757.1693593066",
    //   events: [
    //       {
    //           name: "start_association",
    //           params: {
    //               "session_id": "1705858815"
    //           }
    //       }
    //   ]}
    // })
    window.mt("send", "pageview", { page_title: 'Home', label: 'Asociación desde Home', category: 'Association' });
    navigate(`/${Messages.route_products.id}`);
  };

  return (
    <div className="container">
      <div className="home__img">
        <div className="home__text">
          <p className="secondary__text">
            {formatMessage(Messages.home_title_part1)}
            <span className="main__text">
              {formatMessage(Messages.home_title_part2)}
            </span>
          </p>
          <p className="main__text">
            {formatMessage(Messages.home_title_part3)}
            <span className="secondary__text">
              {formatMessage(Messages.home_title_part4)}
            </span>
          </p>
          <button onClick={booking}>
            {formatMessage(Messages.home_button)}
          </button>
        </div>
      </div>
      <Club />
      <Benefits />
      {isMobile ? <WinesMobile /> : <WinesDesktop />}
      <Reviews />
    </div>
  );
};
