import { IntlProvider} from "react-intl";
import { useEffect, useState, createContext } from "react";
import Messages from "./lang/Messages";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AboutClub } from "./components/About/AboutClub";
import { ProductAdmin } from "./components/Admin/ProductAdmin";
import { LocalityAdmin } from "./components/Admin/LocalityAdmin";
import { Checkout } from "./components/Checkout/Checkout";
import { ApprovedPayment } from "./components/Checkout/Steps/ApprovedPayment";
import { RejectedPayment } from "./components/Checkout/Steps/RejectedPayment";
import { Contact } from "./components/Contact/Contact";
import { Home } from "./components/Home/Home";
import { Header } from "./components/Layouts/Header";
import { Footer } from "./components/Layouts/Footer";
import { AboutRegister } from "./components/Register/AboutRegister";
import { Association } from "./components/Register/Association";
import { Register } from "./components/User/Register";
import { Selections } from "./components/Selections/Selections";
import { SelectionDetail } from "./components/Selections/SelectionDetail";
import { Product } from "./components/Types";
import { Login } from "./components/User/Login";
import ReactGA from "react-ga4";
import { UaEventOptions } from "react-ga4/types/ga4";
import { ProductCreateEdit } from "./components/Admin/ProductCreateEdit";
import { delSessionRedis, getSessionRedis } from "./services/endPoints";
import { v4 } from "uuid";
import packageJson from '../package.json';

export const FormDataContext = createContext({
  currentProduct: {} as Product,
  setCurrentProduct: (value: Product) => {},
  isCheckout: false as boolean,
  setIsCheckout: (value: boolean) => {},
  isMobile: false as Boolean,
  isMinWidth800: false as Boolean,
  setAccessToken: (token: string | undefined) => {},
  accessToken: "" as string | undefined,
  ga4Event: (data: UaEventOptions | string, params?: any) => {},
  formattedNumber: new Intl.NumberFormat('en-AR', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })
});

function App() {

  console.log("App Version:", packageJson.version)

  const [isCheckout, setIsCheckout ] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = useState<Product>({} as Product);
  const [isMobile, setIsMobile] = useState<Boolean>(window.innerWidth <= 600);
  const [isMinWidth800, setIsMinWidth800] = useState<Boolean>(window.innerWidth <= 800);
  const [accessToken, setAccessToken] = useState<string | undefined>();

  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

  const measurementId = process.env.REACT_APP_GA4_MEASUREMENT_ID || "G-8B1WRN5G27"
  ReactGA.initialize(measurementId);

  useEffect(() => {
    let nodeText = "(function(w,d,t,u,n,a,m){w['MauticTrackingObject']=n;" +
    "w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},a=d.createElement(t)," +
    "m=d.getElementsByTagName(t)[0];a.async=1;a.src=u;m.parentNode.insertBefore(a,m)" +
    "})(window,document,'script','https://unico.wine/mtc.js','mt');";

    addScriptIndex(nodeText, "script", true); 

    function addScriptIndex(scriptText: string, tag: string, isHead?: boolean) {
      let scriptTag = document.createElement(tag);
      let scriptElement = document.createTextNode(scriptText);
      scriptTag.appendChild(scriptElement);
      isHead ? document.head.appendChild(scriptTag) : document.body.appendChild(scriptTag);
    }

    window.addEventListener("resize", handleResize);
    const userId = sessionStorage.getItem("userId") || v4();
    handleSessionFromRedis(userId)

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [])

  const handleLogout = () => {
    const userId =  sessionStorage.getItem("userId")
    console.log(userId)
    delSessionRedis(`userSession:${userId}`).then(() => {
      sessionStorage.removeItem("userId")
      // navigate(`/${Messages.route_home.id}`)
    })
  }

  const handleSessionFromRedis = (userId: string) => {
    console.log("USER", userId)
    getSessionRedis(`userSession:${userId}`).then((response) => {
      const { access_token } = response.data;
      if(access_token) setAccessToken(access_token)
      // if(!access_token) {
      //   window.location.href.includes("login") ? navigate("") : navigate(`/${Messages.route_login.id}`)
      // }
    })
    .catch((error) => {
      const messageError = error.response.data
      console.log(messageError)
      if(messageError === 'USER_NOT_FOUND') handleLogout()
    });
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
    setIsMinWidth800(window.innerWidth <= 800);
  };

  // const formatNumberOptions: FormatNumberOptions = {
  //   style: 'currency',
  //   currency: 'narrowSymbol',
  //   minimumFractionDigits: 2
  // };
  // const formats = {
  //     number: {
  //         default: formatNumberOptions,
  //     },
  // };

  const formattedNumber = new Intl.NumberFormat('en-AR', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  const baseUrl = process.env.PUBLIC_URL || "";

  return (
    <FormDataContext.Provider value={{ currentProduct, setCurrentProduct, isCheckout, setIsCheckout, isMobile, isMinWidth800, accessToken, setAccessToken, ga4Event: ReactGA.event, formattedNumber }} >
      <IntlProvider locale={navigator.language}>
        <Router>
          <Header />
          <Routes>
            <Route path={`${baseUrl}`} Component={Home} />
            <Route path={`${baseUrl}/${Messages.route_club.id}`} Component={AboutClub} />
            <Route path={`${baseUrl}/${Messages.route_products.id}`} Component={Selections} />
            <Route path={`${baseUrl}/${Messages.route_contact.id}`} Component={Contact} />
            <Route path={`${baseUrl}/${Messages.route_prod_admin.id}`} Component={ProductAdmin} />
            <Route path={`${baseUrl}/${Messages.route_prod_manage.id}`} Component={ProductCreateEdit} />
            <Route path={`${baseUrl}/${Messages.route_products.id}`} Component={LocalityAdmin} />
            <Route path={`${baseUrl}/${Messages.route_selection_detail.id}`} Component={SelectionDetail} />
            <Route path={`${baseUrl}/${Messages.route_checkout.id}`} Component={Checkout} />
            <Route path={`${baseUrl}/${Messages.route_rejected_payment.id}`} Component={RejectedPayment} />
            <Route path={`${baseUrl}/${Messages.route_approved_payment.id}`} Component={ApprovedPayment} />
            <Route path={`${baseUrl}/${Messages.route_contact.id}`} Component={Contact} />
            <Route path={`${baseUrl}/${Messages.route_about_register.id}`} Component={AboutRegister} />
            <Route path={`${baseUrl}/${Messages.route_association.id}`} Component={Association} />
            <Route path={`${baseUrl}/${Messages.route_register.id}`} Component={Register} />
            <Route path={`${baseUrl}/${Messages.route_login.id}`} Component={Login} />
          </Routes>
          { !isCheckout && <Footer />}
        </Router>
      </IntlProvider>
    </FormDataContext.Provider>
  );
}

export default App;
