import { useState } from "react";
import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import "./Register.scss";
import { FormBuilder } from "../Commons/FormBuilder";
import { getRegisterFields } from "./Metadata";
import { createContact } from "../../services/endPoints";

export const Register = () => {
  const { formatMessage } = useIntl();

  const [formData, setFormData] = useState<Record<string, string>>(
    {} as Record<string, string>
  );
  const [errors, setErrors] = useState<Record<string, string>>(
    {} as Record<string, string>
  );

  const handleRegister = () => {
    const { password, confirmPassword } = formData
    if(!password && !confirmPassword) {
      setErrors({ ...errors, passwordError: 'Debe ingresar una contraseña', confirmPasswordError: 'Debe confirmar la contraseña' });
      return;
    }
    if(password !== confirmPassword) {
      setErrors({ ...errors, register: 'Las contraseñas no coinciden' });
      return;
    }
      createContact({ ...formData, stage: 1 })
  };

  const handleError = (keyError: string, newValue: any, message: string) => {
    let newError = "";
    if (
      !newValue ||
      (!!newValue && typeof newValue === "string" && !newValue.trim())
    )
      newError = message;

    setErrors({ ...errors, [keyError]: newError });
  };

  const handleFormData = (key: string | Record<string, string | boolean>, value: string | undefined) => {
    if(typeof key === "string")
      setFormData({ ...formData, [key]: value || "" });
  };

  return (
    <div className="register__container">
      <FormBuilder formFields={getRegisterFields()} formData={formData} handleFormData={handleFormData} handleError={handleError} errors={errors} />
      {!!errors.register && <p className="password__error">{errors.register}</p>}
      <button onClick={handleRegister}>{formatMessage(Messages.register_button)}</button>
    </div>
  );
};
