import { Autocomplete, TextField } from "@mui/material";
import { FormBuilder } from "../Commons/FormBuilder";
import { Payment } from "./Steps/Payment";
import type { StepperProps } from "../Types"
import { editContactAddress, createContact, createCustomer, getContactBy, getCustomer, getIpAddress, getProvinces } from "../../services/endPoints";

const createCustomerAction = async (payload: Record<string, string | number | boolean> | undefined) => {
  if(!payload) return;
  const { email, firstName, lastName, phoneNumber } = payload
  try {
    const ipAddressResponse = await getIpAddress();
    const contactResponse = await getContactBy(`email:${email}`);
    let newContact;
    if (!contactResponse.data.contacts.total) {
      newContact = await createContact({
        email,
        firstname: firstName,
        lastname: lastName,
        ipAddress: ipAddressResponse.data.ip,
        mobile: phoneNumber,
        stage: 3
      });
    }      

    const contact = newContact ? newContact.data.contact.id : contactResponse.data.contacts.id

    sessionStorage.setItem('c_id', contact);
    const customerResponse = await getCustomer(contact)

    if(!customerResponse.data.length)
      await createCustomer({
        contact,
        startPromotionMonth: 1,
      });
  } catch (error) {
    throw error
  }
}

const updateCustomerAddressAction = async (payload: Record<string, string | number | boolean> | undefined) => {
  if(!payload) return;

  const { street, streetNumber, floor, door, province, city, postalCode } = payload

  const payloadAddress = {
    address1: `${street} ${streetNumber}`,
    floor,
    door,
    city,
    state: province,
    country: 'Argentina',
    zipcode: postalCode,
    stage: 4
  }

  //AGREGAR NORMALIZACION GMAPS

  try {
    const contactId = sessionStorage.getItem('c_id') || "";
    await editContactAddress(contactId, payloadAddress) 
    sessionStorage.removeItem("c_id");
  } catch (error) {
    throw error
  }
}

const STEPS = [
  {
    title: "Datos de contacto",
    mobileTitle: "Contacto",
    component: FormBuilder,
    action: createCustomerAction,
    components: [
      {
        id: "firstName",
        component: TextField,
        type: "text",
        label: "Nombre",
        error: "Debe ingresar nombre",
        helperText: "Nombre",
        required: true,
        additionalAttributes: {
          fullWidth: true,
        },
      },
      {
        id: "lastName",
        component: TextField,
        type: "text",
        label: "Apellido",
        error: "Debe ingresar apellido",
        helperText: "Apellido",
        required: true,
        additionalAttributes: {
          fullWidth: true,
        },
      },
      {
        id: "phoneNumber",
        component: TextField,
        type: "number",
        label: "Número de teléfono",
        error: "Debe ingresar un número de teléfono",
        helperText: "Teléfono",
        required: true,
        additionalAttributes: {
          fullWidth: true,
        },
      },
      {
        id: "email",
        component: TextField,
        type: "email",
        label: "Email",
        error: "Debe ingresar un email",
        helperText: "Email",
        required: true,
        additionalAttributes: {
          fullWidth: true,
        },
      },
      {
        id: "notes",
        component: TextField,
        type: "text",
        label: "Notas del pedido (opcional)",
        helperText: "Notas del pedido",
        required: false,
        additionalAttributes: {
          fullWidth: true,
          multiline: true,
          rows: 4,
          parentClassName: "association__notes",
        },
      },
    ]
  },
  { title: "Pago", 
    mobileTitle: "Pago", 
    component: Payment, 
    isAutomatic: true,
    components: [], 
    type: "payment"
  },
  {
    title: "Datos de envío",
    mobileTitle: "Envìo",
    component: FormBuilder,
    action: updateCustomerAddressAction,
    components: [
      {
        id: "street",
        component: TextField,
        type: "text",
        label: "Calle",
        error: "Debe ingresar una calle",
        helperText: "Domicilio (Calle)",
        required: true,
        additionalAttributes: {
          fullWidth: true,
          className: 'form__data'
        },
      },
      {
        id: "streetNumber",
        component: TextField,
        type: "text",
        label: "Altura",
        error: "Debe ingresar una altura",
        helperText: "Domicilio (Altura)",
        required: true,
        additionalAttributes: {
          fullWidth: true,
          className: 'form__data'
        },
      },
      {
        id: "floor",
        component: TextField,
        type: "number",
        label: "Piso",
        helperText: "Piso (opcional)",
        required: false,
        additionalAttributes: {
          fullWidth: true,
          className: 'form__data'
        },
      },
      {
        id: "door",
        component: TextField,
        type: "text",
        label: "Departamento",
        helperText: "Dpto (opcional)",
        required: false,
        additionalAttributes: {
          fullWidth: true,
          className: 'form__data'
        },
      },
      {
        id: "province",
        component: Autocomplete,
        label: "Provincia",
        error: "Debe seleccionar una provincia",
        helperText: "Provincia",
        required: true,
        options: getProvinces,
        additionalAttributes: {
          fullWidth: true,
          autoHighlight: true,
          parentClassName: "formassoc__location",
          options: "provinces",
        },
      },
      {
        id: "city",
        component: Autocomplete,
        label: "Ciudad",
        error: "Debe seleccionar una ciudad",
        helperText: "Ciudad",
        required: true,
        additionalAttributes: {
          fullWidth: true,
          autoHighlight: true,
          parentClassName: "formassoc__location",
          options: "cities",
        },
      },
      {
        id: "postalCode",
        component: TextField,
        type: "text",
        label: "Código postal",
        helperText: "Código postal",
        required: true,
        additionalAttributes: {
          fullWidth: true,
          className: 'form__data'
        },
      },
    ]
  }
];

const getSteppers = (): StepperProps[] => {
  return STEPS;
};

export { getSteppers };
