import type { AxiosResponse } from 'axios';
import api from './api';
import type { Locality, Plan, Product } from '../components/Types';

export const getLeadsOrCustomers = (): Promise<AxiosResponse> => {
  return api.run({
    action: 'get',
    endpoint: '/api/contacts',
  });
};

export const getListSegments = (): Promise<AxiosResponse> => {
  return api.run({
    action: 'get',
    endpoint: '/api/segments',
  });
};

export const getListRoles = async (payload: Object): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: '/api/roles',
    data: payload
  })
};

export const getContactsFields = async (): Promise<AxiosResponse> => {
  return api.run({
    action: 'get',
    endpoint: '/api/fields/contact'
  })
}

export const login = async (payload: Object): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: '/api/users/login',
    data: payload
  })
}

export const getContactBy = async (stringSearch: string) => {
  return api.run({
    action: 'get',
    endpoint: `/api/contacts?search=${stringSearch}`
  })
}

export const createContact = async (payload: Object): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: '/api/contacts/new',
    data: payload
  })
}

export const editContactAddress = async (id: string, payload: Object): Promise<AxiosResponse> => {
  return api.run({
    action: 'patch',
    endpoint: `/api/contacts/${id}/edit`,
    data: payload
  })
}

export const submitForm = async (formId: string, payload: Object): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: `/form/submit?formId=${formId}`,
    data: payload
  })
}

export const createSegment = async (payload: Object): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: '/api/segments/new',
    data: payload
  })
}

export const getActiveCart = async (clientId: Number): Promise<AxiosResponse> => {
  return api.run({
    action: 'get',
    endpoint: `api/carts/list/${clientId}`
  })
}

export const addItemCart = async (payload: Product): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: 'api/carts/create',
    data: payload
  })
}

export const deleteItemCart = async (cartId: Number): Promise<AxiosResponse> => {
  return api.run({
    action: 'delete',
    endpoint: `api/carts/delete/${cartId}`
  })
}

export const getClientAddress = async (clientId: Number): Promise<AxiosResponse> => {
  return api.run({
    action: 'get',
    endpoint: `api/address/find/${clientId}`
  })
}

export const deleteAddress = async (addressId: Number): Promise<AxiosResponse> => {
  return api.run({
    action: 'delete',
    endpoint: `api/address/delete/${addressId}`
  })
}

export const getPaymentById = async (id: Number): Promise<AxiosResponse> => {
    return api.run({
      action: 'get',
      endpoint: `api/payments/find/${id}`
    })
}
  
  export const editPayment = async (payload: Object): Promise<AxiosResponse> => {
    return api.run({
      action: 'patch',
      endpoint: 'api/payments/edit',
      data: payload
    })
  }
  
  export const deletePayment = async (addressId: Number): Promise<AxiosResponse> => {
    return api.run({
      action: 'delete',
      endpoint: `api/payments/delete/${addressId}`
    })
  }

  export const createSubscription = async (payload: Object): Promise<AxiosResponse> => {
    return api.run({
      action: 'post',
      endpoint: 'api/subscriptions/create',
      data: payload
    })
  }
    
    export const editSubscription = async (payload: Object): Promise<AxiosResponse> => {
      return api.run({
        action: 'patch',
        endpoint: 'api/subscriptions/edit',
        data: payload
      })
    }

export const getOrdersByClient = async (clientId: Number): Promise<AxiosResponse> => {
  return api.run({
    action: 'get',
    endpoint: `api/salesOrder/find/${clientId}`
  })
}

export const createOrder = async (payload: Object): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: 'api/salesOrder/create',
    data: payload
  })
}

export const editOrder = async (payload: Object): Promise<AxiosResponse> => {
  return api.run({
    action: 'patch',
    endpoint: 'api/salesOrder/edit',
    data: payload
  })
}

export const deleteOrder = async (orderId: Number): Promise<AxiosResponse> => {
  return api.run({
    action: 'delete',
    endpoint: `api/salesOrder/delete/${orderId}`
  })
}

export const getTrackingByOrder = async (orderId: Number): Promise<AxiosResponse> => {
  return api.run({
    action: 'get',
    endpoint: `api/shipment/find/${orderId}`
  })
}

export const createTracking = async (payload: Object): Promise<AxiosResponse> => {
  return api.run({
    action: 'patch',
    endpoint: 'api/shipment/create',
    data: payload
  })
}

export const deleteTracking = async (id: Number): Promise<AxiosResponse> => {
  return api.run({
    action: 'delete',
    endpoint: `api/shipment/delete/${id}`
  })
}

export const getProducts = async (): Promise<AxiosResponse> => {
  return api.run({
    action: 'get',
    endpoint: 'api/products/list'
  })
}

export const createProduct = async (payload: Product): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: 'api/products/create',
    data: payload
  })
}

export const editProduct = async (payload: Product): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: 'api/products/edit',
    data: payload
  })
}

export const deleteProduct = async (id: Number): Promise<AxiosResponse> => {
  return api.run({
    action: 'delete',
    endpoint: `api/products/delete/${id}`
  })
}

export const syncProducts = async (): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: `api/products/sync`
  })
}

export const getPlanByid = async (id: number): Promise<AxiosResponse> => {
  return api.run({
    action: 'get',
    endpoint: `api/plans/find/${id}`
  })
}

export const createPlan = async (payload: Plan): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: 'api/plans/create',
    data: payload
  })
}

export const createMultiplePlans = async(plans: Plan[]) => {
  return Promise.all(plans.map(async (plan) => await createPlan(plan)))
}

export const editPlan = async (payload: Object): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: 'api/plans/edit',
    data: payload
  })
}

export const deletePlans = async (id: Number): Promise<AxiosResponse> => {
  return api.run({
    action: 'delete',
    endpoint: `api/plans/delete/${id}`
  })
}

export const getLocalities = async (): Promise<AxiosResponse> => {
  return api.run({
    action: 'get',
    endpoint: 'api/localities/list'
  })
}

export const createLocality = async (data: Locality): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: 'api/localities/create',
    data
  })
}

export const uploadImages = async(data: FormData): Promise<AxiosResponse> => {
  return api.uploadImage({
    action: 'post',
    endpoint: 'images',
    data
  })
}

export const getProvinces = async (): Promise<AxiosResponse> => {
  return api.runExternal({
    action: 'get',
    endpoint: 'https://apis.datos.gob.ar/georef/api/provincias'
  })
}

export const getCitiesProvince = async (endpoint: string, params: object): Promise<AxiosResponse> => {
  return api.runExternal({
    action: 'get',
    endpoint: `https://apis.datos.gob.ar/georef/api/${endpoint}`,
    params
  })
}

export const getIpAddress = async (): Promise<AxiosResponse> => {
  return api.runExternal({
    action: 'get',
    endpoint: 'https://api.ipify.org?format=json'
  })
}

export const saveSessionRedis = async (data: Record<string, string | undefined | null>): Promise<AxiosResponse> => {
  return api.googleLogin({
    action: 'post',
    endpoint: 'session',
    data
  })
}

export const getSessionRedis = async (userId: string | undefined | null): Promise<AxiosResponse> => {
  return api.googleLogin({
    action: 'get',
    endpoint: `session/${userId}`,
  })
}

export const delSessionRedis = async (userId: string | undefined | null): Promise<AxiosResponse> => {
  return api.googleLogin({
    action: 'delete',
    endpoint: `session/${userId}`,
  })
}

export const preApprovalMP = async (data: Record<string, string | number | object | undefined>): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: 'api/payments/create',
    data
  })
}

export const sendEmail = async (data: Record<string, string | number | object>): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: 'api/payments/create',
    data
  })
}

export const sendGa4CustomEvent = async (data: Record<string, string | number | object>): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: `https://www.google-analytics.com/mp/collect?measurement_id=${process.env.REACT_APP_GA4_MEASUREMENT_ID}&api_secret=${process.env.REACT_APP_GA4_SECRET}`,
    data
  })
}

export const getCustomer = async (contactId: string): Promise<AxiosResponse> => {
  return api.run({
    action: 'get',
    endpoint: `/api/customers/find/${contactId}`,
  })
}

export const createCustomer = async (payload: Object): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: '/api/customers/create',
    data: payload
  })
}

export const webhookCall = async (payload: Object): Promise<AxiosResponse> => {
  return api.run({
    action: 'post',
    endpoint: 'https://hook.us1.make.com/hjwq9chz194k5rqfuis4o3a835uwhejk',
    data: payload
  })
}
